@if (href) {
  @if (internal && !this.target) {
    <a [routerLink]="href | urlFixed">
      <ng-container [ngTemplateOutlet]="inner"/>
    </a>
  } @else {
    <a [href]="href | urlFixed" [target]="linkTarget">
      <ng-container [ngTemplateOutlet]="inner"/>
    </a>
  }
} @else {
  <div class="fake-link">
    <ng-container [ngTemplateOutlet]="inner"/>
  </div>
}

<ng-template #inner>
  <span class="content"><ng-content/></span>
  @if (icon) {
    <sps-icon [icon]="iconName" class="filled"/>
  }
</ng-template>
